import {
  DISASTER_RESPONSE_FUND,
  DONATE_WIDGET_MODES,
  POLIO_PLUS_FUND,
  ROTARY_DIRECT,
} from '@constants/index';

export default (destination: string): DONATE_WIDGET_MODES => {
  switch (destination) {
    case DISASTER_RESPONSE_FUND:
      return DONATE_WIDGET_MODES.DRF;
    case ROTARY_DIRECT:
      return DONATE_WIDGET_MODES.RD;
    case POLIO_PLUS_FUND:
      return DONATE_WIDGET_MODES.EP;
    default:
      return DONATE_WIDGET_MODES.GIV;
  }
};
