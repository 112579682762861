export const FEATURE_SUPPLIER_PORTAL_LOGIN = 'FEATURE_SUPPLIER_PORTAL_LOGIN';
export const FEATURE_TEST_EMAIL = 'FEATURE_TEST_EMAIL';
export const FEATURE_LEADS_JOIN_FORMS = 'FEATURE_LEADS_JOIN_FORMS';
export const FEATURE_LEADS_MML = 'FEATURE_LEADS_MML';
export const FEATURE_LEADS_CANDIDATE_PROFILE =
  'FEATURE_LEADS_CANDIDATE_PROFILE';
export const FEATURE_LOGIN_AUTH_CONTROLLER = 'FEATURE_LOGIN_AUTH_CONTROLLER';
export const FEATURE_DELEGATION = 'FEATURE_DELEGATION';
export const FEATURE_MY_DONATIONS_GOVERNMENT_ID =
  'FEATURE_MY_DONATIONS_GOVERNMENT_ID';
export const FEATURE_CONTACT_US_PAGE = 'FEATURE_CONTACT_US_PAGE';
export const FEATURE_SITECORE_HEADER = 'FEATURE_SITECORE_HEADER';
export const FEATURE_SITECORE_FOOTER = 'FEATURE_SITECORE_FOOTER';
export const FEATURE_SITECORE_HOMEPAGE = 'FEATURE_SITECORE_HOMEPAGE';
export const FEATURE_SITECORE_IS_PROD = 'FEATURE_SITECORE_IS_PROD';
export const FEATURE_SITECORE_LANDING_PAGE = 'FEATURE_SITECORE_LANDING_PAGE';
export const FEATURE_DOMINO_METADATA = 'FEATURE_DOMINO_METADATA';
export const FEATURE_DOMINO_ORGANIZATION = 'FEATURE_DOMINO_ORGANIZATION';
export const FEATURE_DOMINO_CONFERENCE = 'FEATURE_DOMINO_CONFERENCE';
export const FEATURE_POLIOPLUS_FUND = 'FEATURE_POLIOPLUS_FUND';
export const FEATURE_DOMINO_CDM_PI1 = 'FEATURE_DOMINO_CDM_PI1';

export const featureFlags = {
  [FEATURE_SUPPLIER_PORTAL_LOGIN]: process.env.FEATURE_SUPPLIER_PORTAL_LOGIN,
  [FEATURE_TEST_EMAIL]: process.env.GATSBY_FEATURE_TEST_EMAIL,
  [FEATURE_LEADS_JOIN_FORMS]: process.env.GATSBY_FEATURE_LEADS_JOIN_FORMS,
  [FEATURE_LEADS_MML]: process.env.GATSBY_FEATURE_LEADS_MML,
  [FEATURE_LEADS_CANDIDATE_PROFILE]:
    process.env.GATSBY_FEATURE_LEADS_CANDIDATE_PROFILE,
  [FEATURE_LOGIN_AUTH_CONTROLLER]:
    process.env.GATSBY_FEATURE_LOGIN_AUTH_CONTROLLER,
  [FEATURE_DELEGATION]: process.env.GATSBY_FEATURE_DELEGATION,
  [FEATURE_MY_DONATIONS_GOVERNMENT_ID]:
    process.env.GATSBY_FEATURE_MY_DONATIONS_GOVERNMENT_ID,
  [FEATURE_CONTACT_US_PAGE]: process.env.GATSBY_FEATURE_CONTACT_US_PAGE,
  [FEATURE_SITECORE_HEADER]: process.env.GATSBY_FEATURE_SITECORE_HEADER,
  [FEATURE_SITECORE_FOOTER]: process.env.GATSBY_FEATURE_SITECORE_FOOTER,
  [FEATURE_SITECORE_HOMEPAGE]: process.env.GATSBY_FEATURE_SITECORE_HOMEPAGE,
  [FEATURE_SITECORE_IS_PROD]: process.env.GATSBY_FEATURE_SITECORE_IS_PROD,
  [FEATURE_SITECORE_LANDING_PAGE]:
    process.env.GATSBY_FEATURE_SITECORE_LANDING_PAGE,
  [FEATURE_DOMINO_METADATA]: process.env.GATSBY_FEATURE_DOMINO_METADATA,
  [FEATURE_DOMINO_ORGANIZATION]: process.env.GATSBY_FEATURE_DOMINO_ORGANIZATION,
  [FEATURE_DOMINO_CONFERENCE]: process.env.GATSBY_FEATURE_DOMINO_CONFERENCE,
  [FEATURE_POLIOPLUS_FUND]: process.env.GATSBY_FEATURE_POLIOPLUS_FUND,
  [FEATURE_DOMINO_CDM_PI1]: process.env.GATSBY_FEATURE_DOMINO_CDM_PI1,
};

export type Feature = keyof typeof featureFlags;

export const features = Object.keys(featureFlags) as Feature[];
export const enabledFeatures = features.filter(
  (feature: Feature) => featureFlags[feature] === 'true'
);

export const isEnabled = (feature: Feature | Feature[]): boolean => {
  const featuresList = Array.isArray(feature) ? feature : [feature];

  return enabledFeatures.some(item => featuresList.includes(item));
};
