import { getDestinationPath } from '@utils/getDestinationPath';
import getDonateWidgetMode from '@utils/getDonateWidgetMode';
import getDonateWidgetURL from '@utils/getDonateWidgetURL';
import {
  getLangFromPath,
  isLocalizedPath,
  localizedPath,
} from '@utils/localized-path';

import {
  DRUPAL_ENTITY_ID,
  MY_CMS_URL_PROD,
  MY_CMS_URL_UAT,
  WIDGET_DESTINATION_PATHS,
} from '@constants/index';

import { DonateWidget } from '@typings/widget';

/**
 * Here below is all the logic covers Donate Widget integration onto page
 *
 * To be called in 'gatsby-browser.js'
 */
export const waitForDonateWidget = (
  selector: string,
  checkFrequencyInMs: number,
  timeoutInMs: number
) => {
  const { destination, isProd } = getDestinationPath(WIDGET_DESTINATION_PATHS);

  const existedScript: HTMLScriptElement | null = document.querySelector(
    'script[src*="donateWidget.js"]'
  );

  const script: HTMLScriptElement =
    existedScript || document.createElement('script');

  if (!existedScript) {
    script.src = getDonateWidgetURL();
    script.type = 'text/javascript';
    document.head.prepend(script);
  }

  const interval = setInterval(() => {
    const element = document.querySelector(selector);
    const elementContainer = document.getElementById('donate-widget');

    const user = window.__gatsby_user;
    const gatsbyLanguage = window.__gatsby_language;

    const { pathname, origin } = window.location;

    const donateWidget = () =>
      (window.donateWidget as DonateWidget)(
        isProd ? MY_CMS_URL_PROD : MY_CMS_URL_UAT,
        DRUPAL_ENTITY_ID,
        {},
        document.getElementById('donate-widget'),
        getDonateWidgetMode(destination)
      );

    // CASE 1
    // Breaks interval loop if donateWidget and element are loaded on the page.
    // This means no other activities is needed.
    if (window.donateWidget && element !== null) {
      clearInterval(interval);
    }
    // CASE 2
    // Makes localized navigation or fires widget with params
    else if (
      window.donateWidget &&
      element === null &&
      elementContainer !== null
    ) {
      // CASE 2.1
      // If current path is not localized then add
      // localization and navigate to localized path.
      if (
        !isLocalizedPath(pathname) &&
        gatsbyLanguage !== getLangFromPath(pathname)
      ) {
        clearInterval(interval);
        window.location.href =
          origin + localizedPath(pathname, getLangFromPath(pathname) || '');
      }
      // CASE 2.2
      // Make delayed donateWidget call
      else if (
        (user &&
          isLocalizedPath(pathname) &&
          gatsbyLanguage === getLangFromPath(pathname)) ||
        (!user && gatsbyLanguage === getLangFromPath(pathname))
      ) {
        clearInterval(interval);
        setTimeout(() => donateWidget(), 2000);
      }
    }
    // CASE 3
    // Breaks interval loop after timeout
    else if (
      (user && gatsbyLanguage !== getLangFromPath(pathname)) ||
      (!user && gatsbyLanguage === getLangFromPath(pathname))
    ) {
      // CASE 3.1
      // If current path doesn't match gatsbyLanguage and user is logged in then
      // make full page reload using language from path for localization
      setTimeout(() => clearInterval(interval), timeoutInMs);
      window.location.href =
        origin + localizedPath(pathname, getLangFromPath(pathname) || '');
    }
    // CASE 3.2
    // Otherwise no additional checks are needed.
    // We can clear interval as og widget was not found during timeoutInMs.
    else setTimeout(() => clearInterval(interval), timeoutInMs);
  }, checkFrequencyInMs);
};
